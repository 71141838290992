<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Consulta de nóminas
            </div>
            
            <v-form :disabled="loading">
                <v-row class="mb-3">
                    <v-col cols="4" sm="3" md="2" lg="2" xl="1">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            type="number"
                            min="0"
                            v-model="filtros.Ano"
                            label="Año"
                            @change="onChangeFiltroAno"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :item-class="selectedRow"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRow"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1 docs-table"
                dense
                >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Mes="{ item }">
                    {{ formatMes(item.Mes) }}
                </template>
            </v-data-table>

            <v-row justify="center">
              <v-btn
              :to="selectedItemNomLink()"
              :disabled="selectedItem == null"
              :loading="selectedItem!=null && loading"
              color="primary"
              class="mr-2 mt-6 mb-4">
              Ver Nómima
              </v-btn>
              <v-btn
              :disabled="selectedItem == null"
              :loading="selectedItem!=null && (loading || downloading)"
              color="primary"
              class="mt-6 mb-4"
              @click="onBtnDescargar">
              Descargar
              </v-btn>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            downloading: false,
            filtros: {
              Ano: null
            },
            selectedItem: null,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Año', width: 50, sortable: false, value: 'Ano' },
                { text: 'Mes', width: 50, sortable: false, value: 'Mes' },
                { text: 'Nombre', width:200, sortable: false, value: 'PerNom' },
                // { text: 'Debug', width:200, sortable: false, value: 'Nombre' },
                { text: 'Tipo', width: 50, sortable: false, value: 'Tipo' },
            ],
            dtOptionsLoaded: false
        }
    },
    watch: {
      options: {
        handler () {
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // }
    },
    mounted() {
      this.filtros.Ano = new Date().getFullYear();
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_CONSULTA_SALARIO',
            EmpId: this.empId,
            PerId: this.perId,
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            Ano: this.filtros.Ano != '' ? this.filtros.Ano : null
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                  items,
                  total,
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar las nóminas");
            }
          });
        })
      },
      getNominaFromApi(){
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DESCARGA_SALARIO',
            EmpId: this.empId,
            PerId: this.perId,
            FileName: this.selectedItem.Nombre
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al obtener el documento");
            }
          });
        })
      },
      loadData(){
        this.selectedItem = null;
        this.getDataFromApi()
        .then(data => {
            this.dataRows = data.items
            this.totalDataRows = data.total
        });
      },
      
      onChangeFiltroAno() {
          this.filtros.Ano = Math.trunc(this.filtros.Ano);
          this.loadData();
      },
      descargarDocumento(doc, docName){
        this.downloading = true;

        // Datos del fichero
        const contentType = 'application/pdf';
        
        // Covertir de base 64 a Blob
        const byteCharacters = atob(doc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});

        // Descarga del fichero
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a"); 
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = docName; // Nombre del fichero
        a.click();
        a.remove();

        this.downloading = false;
      },
      onClickRow(item){
        this.selectedItem = item;
      },
      onBtnDescargar(){
        this.getNominaFromApi()
        .then(data => {
          if(data.item === false) { 
            alert("Archivo no encontrado");
            return; 
          }
          this.descargarDocumento(data.item.File, data.item.FileName);
        });
      },
      selectedItemNomLink(){
        if(this.selectedItem == null) { return '/'; }
        const baseUrl = '/nominas-consulta/';
        return baseUrl + btoa(this.selectedItem.Nombre.trim());
      },
      selectedRow(item) {
          if(this.selectedItem == null) { return ''; }
          if(item.Nombre === this.selectedItem.Nombre){
              return 'selected-row';
          }
          return '';
      },
      formatMes (value) {
        if(value == null) { return ''; }
        var meses = [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ];
        return meses[value-1];
      }
    } 
})
</script>

<style>
  /* .docs-table > .v-data-table__wrapper > table {
      border-collapse: collapse;
  } */
  .selected-row {
      /* border: 4px solid #ff4848; */
      background-color: #aecaff;
  }
  .selected-row:hover {
      background-color: #a3c3ff !important;
  }
</style>
